import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import { useOutletContext, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { ProductSort, ProductList } from '../sections/@dashboard/products';
// mock
import SHORTS from '../_mock/shorts';

// ----------------------------------------------------------------------

export default function ShortsPage() {
  const [sortOrder, setSortOrder] = useState('Newest');
  const { filterValue } = useOutletContext();
  console.log(filterValue);

  const filteredProducts = SHORTS.filter((product) => product.name.toLowerCase().includes(filterValue.toLowerCase()));

  if (sortOrder === 'Oldest') {
    filteredProducts.reverse();
  }

  return (
    <>
      <Helmet>
        <title> The Fable Factory </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 2 }}>
          Shorts{' '}
          <NavLink to="/dashboard/videos" style={{ fontSize: '15px' }}>
            Videos
          </NavLink>
        </Typography>

        <Stack direction="row" flexWrap="wrap-reverse" alignItems="center" justifyContent="flex-end" sx={{ mb: 5 }}>
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductSort sortOrder={sortOrder} onSortOrderChange={setSortOrder} />
          </Stack>
        </Stack>

        <ProductList products={filteredProducts} />
      </Container>
    </>
  );
}
