import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, Container, Paper, Stack, styled, Typography } from '@mui/material';
// components
import { Link, useParams } from 'react-router-dom';
import { ProductSort, ProductList } from '../sections/@dashboard/products';
// mock
import PRODUCTS from '../_mock/videos';

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

export default function VideoPlayerPage() {
  const { videoUrl } = useParams();

  const name = 'test';
  const cover = 'test';
  return (
    <>
      <Helmet>
        <title> Dashboard: Products | Minimal UI </title>
      </Helmet>

      <Container maxWidth="xl">
        <Card
          sx={{
            height: '100%',
            minHeight: `70vh`,
            maxHeight: `30vh`,
            p: 1,
          }}
        >
          <Paper
            sx={{
              height: '100%',
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <iframe
              width="100%"
              height="100%"
              src={`https://www.youtube.com/embed/${videoUrl}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Paper>

          <Stack spacing={2} sx={{ p: 3 }}>
            <Link color="inherit" underline="hover">
              <Typography variant="subtitle2">{name}</Typography>
            </Link>
          </Stack>
        </Card>
      </Container>
    </>
  );
}
