import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';
// utils
// components

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, videoUrl } = product;

  return (
    <Card>
      <NavLink to={`/dashboard/videoPlayer/${videoUrl}`}>
        <Box sx={{ pt: '100%', position: 'relative' }}>
          <StyledProductImg alt={name} src={cover} />
        </Box>
      </NavLink>

      <Stack spacing={2} sx={{ p: 2, height: 70 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2">{name}</Typography>
        </Link>
      </Stack>
    </Card>
  );
}
