import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'oldest', label: 'Oldest' },
  { value: 'newest', label: 'Newest' },
];

ShopProductSort.propTypes = {
  sortOrder: PropTypes.string,
  onSortOrderChange: PropTypes.func,
};

export default function ShopProductSort({ sortOrder, onSortOrderChange }) {
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = (value) => {
    onSortOrderChange(value);
    setOpen(null);
  };

  console.log('sortOrder', sortOrder);

  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Sort By:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {sortOrder}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => {
          console.log(option.label === sortOrder);
          return (
            <MenuItem
              key={option.value}
              selected={option.label === sortOrder}
              onClick={() => handleClose(option.label)}
              sx={{ typography: 'body2' }}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}
