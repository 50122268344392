import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const videosList = [
  {
    name: 'The Aliens Have Stolen the Ice Cream 🍦👽🚀',
    url: '1UfmYz5rrWg',
    picture: '012_the_aliens_have_stolen_the_ice_cream',
  },
  {
    name: 'The Fantastic Animal Games  🐢🦉🐇',
    url: 'lwMkYK_US_Y',
    picture: '011_the_fantastic_animal_games',
  },
  {
    name: 'Finding Wheels for Bella 🚲🐁🐭',
    url: '2EHCNCinSkQ',
    picture: '010_finding_wheels_for_bella',
  },
  {
    name: 'A Recipe for Joy 🥗🍲👨‍🍳',
    url: 'bHx0eBhNtZk',
    picture: '009_a_recipe_for_joy',
  },
  {
    name: 'Lily and the Magic Wand of Colours 👩🌈🪄',
    url: '7fCsD6W50gU',
    picture: '008_lily_and_the magic_wand_of colours',
  },
  {
    name: 'The Three Little Astronauts 🚀🌑',
    url: 'dkS8r-2cC2o',
    picture: '007_the-three_little_astronauts',
  },
  {
    name: 'The Robots are in Trouble 👦🏻🤖💥',
    url: 'RN50FJykqc4',
    picture: '006_the_robots_are_in_trouble',
  },
  {
    name: 'The Woman with Branches for Hair 👩🌳🐦',
    url: '-7Z4pmdXHL8',
    picture: '005_the_woman_with_branches_for_hair',
  },
  { name: 'The Six-Legged Adventurer 🕷️🐜', url: 'xaiRZbDZnHQ', picture: '004_the_six-legged_adventurer' },
  {
    name: 'The Missing Brothers and The TV World 👦👦🏼📺',
    url: 'MjX6HFcMVB4',
    picture: '003_the_missing_brothers_and_the_tv_world',
  },
  { name: 'Lobo Loses His Howl 🐺👦', url: 'xs9OG-GemEc', picture: '002_lobo_loses_his_howl' },
  { name: 'Peppy the Pigeon 🎤🕊️🎵', url: 'fstAhZ0CfLs', picture: '001_peppy_the_pigeon' },
];

// ----------------------------------------------------------------------

const videos = videosList.map((video, index) => ({
  id: faker.datatype.uuid(),
  cover: `/assets/images/squared_covers/${video.picture}.jpg`,
  name: video.name,
  videoUrl: video.url,
  price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
}));

export default videos;
