import { faker } from '@faker-js/faker';

// ----------------------------------------------------------------------

const shortsList = [
  {
    name: 'Letter J 🏫📚🎓',
    url: 'lU5X_nXEm2I',
    picture: '018_letter_j',
  },
  {
    name: 'Letter I 🏫📚🎓',
    url: 'g93dS66-Lws',
    picture: '017_letter_i',
  },
  {
    name: 'Letter H 🏫📚🎓',
    url: 'fZjqzqvoTho',
    picture: '016_letter_h',
  },
  {
    name: 'Letter G 🏫📚🎓',
    url: 'xLxCceKT0X0',
    picture: '015_letter_g',
  },
  {
    name: 'Letter F 🏫📚🎓',
    url: 'J8iaDYhIjWc',
    picture: '014_letter_f',
  },
  {
    name: 'Letter E 🏫📚🎓',
    url: 'UT2wQVHLMrM',
    picture: '013_letter_e',
  },
  {
    name: 'Letter D 🏫📚🎓',
    url: 'ELPp6C8SYAc',
    picture: '012_letter_d',
  },
  {
    name: 'Letter C 🏫📚🎓',
    url: 'uFcHdLDX-Pw',
    picture: '011_letter_c',
  },
  {
    name: 'Letter B 🏫📚🎓',
    url: '4rI6rPQK3gE',
    picture: '010_letter_b',
  },
  {
    name: 'Letter A 🏫📚🎓',
    url: 'sZKdDhXnwgc',
    picture: '009_letter_a',
  },
  {
    name: 'Transport 🚗✈️🚂',
    url: 'tybBeuoZUJw',
    picture: '008_transport',
  },
  {
    name: 'Months 🗓️😊',
    url: 'RwrtftmYDUk',
    picture: '007_months',
  },
  {
    name: 'Shapes 🟦🔺🟡',
    url: 'nFQq_r0Qkk4',
    picture: '006_shapes',
  },
  {
    name: 'Planets 🪐🌎',
    url: 'Ek4fa7HYTJo',
    picture: '005_planets',
  },
  {
    name: 'Fruits 🍎🍌🍍',
    url: 'qmc-yPO8U0I',
    picture: '004_fruits',
  },
  {
    name: 'Colours 🌈',
    url: 'KcpTcUGaFhc',
    picture: '003_colours',
  },
  {
    name: 'Animals 🦁🐈',
    url: '9aOpVdF3Z7w',
    picture: '002_animals',
  },
  {
    name: 'Numbers 🤖🔢',
    url: 'ZR94ZFE_5b0',
    picture: '001_numbers',
  },
];

// ----------------------------------------------------------------------

const shorts = shortsList.map((video, index) => ({
  id: faker.datatype.uuid(),
  cover: `/assets/images/shorts_covers/${video.picture}.png`,
  name: video.name,
  videoUrl: video.url,
  price: faker.datatype.number({ min: 4, max: 99, precision: 0.01 }),
}));

export default shorts;
